.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#pin_request_button {
  background-color: rgb(106, 150, 201);
}

#pin_request_button:active {
  background-color: rgb(106, 150, 201);
}

#pin_verify_button {
  background-color: rgb(106, 150, 201);
}

#pin_verify_button:active {
  background-color: rgb(106, 150, 201);
}


#pin_request_button.weui-btn_disabled {
  background-color:rgb(153, 153, 153);
}

#pin_verify_button.weui-btn_disabled {
  background-color:rgb(153, 153, 153);
}

